<template>
  <div class="template-list">
    <div class="searchBox">
      <el-button type="primary" @click="addTemplateDialog">新增模板</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="template_id"
          label="模板id"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_version"
          label="版本号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_desc"
          label="版本描述"
          align="center"
        ></el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" size="medium" class="pointer" @click="deleteTemplate(scope.row.template_id)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="mt10"
      background
      :total="totalPage"
      :page-size="20"
      :current-page.sync="currentPage"
      @current-change="pageChange"
    >
    </el-pagination>
    <!-- 新增模板弹窗 -->
    <el-dialog
      title="选择模板"
      :visible.sync="templateDialog"
      width="1000px"
      :show-close="false"
      :close-on-click-modal="false"
      class="selectTemplate"
    >
      <el-table
        :data="templateTableData"
        border
        style="width: 100%"
        @row-click="selectTemplate"
        :row-style="rowStyle"
      >
        <el-table-column
          prop="draft_id"
          align="center"
          label="草稿id"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          label="时间"
        >
        <template slot-scope="scope">{{$formatDate(scope.row.create_time, 'yyyy-MM-dd HH:mm:ss')}}</template>
        </el-table-column>
        <el-table-column
          prop="user_version"
          align="center"
          label="版本号"
        ></el-table-column>
        <el-table-column
          prop="user_desc"
          align="center"
          label="版本描述"
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="templateDialog = false">取 消</el-button>
        <el-button type="primary" @click="addToTemplate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_templateList,
  $sg_draftList,
  $sp_addToTemplate,
  $sp_deleteTemplate,
} from '@/api/miniapp';

export default {
  data() {
    return {
      totalPage: 0,
      currentPage: 1,
      tableData: [],
      templateDialog: false,
      templateTableData: [],
      templateForm: {
        draft_id: '',
        user_version: '',
        user_desc: '',
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 请求代码模板列表数据
    getTableData() {
      const params = {
        page: this.currentPage,
        pageSize: 20,
      };
      $sg_templateList(params).then((res) => {
        this.tableData = res.data;
        this.totalPage = res.count;
      });
    },
    // 删除模板
    deleteTemplate(id) {
      this.$confirm('确认要删除该模板吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
      })
        .then(() => {
          const params = {
            template_id: id,
          };
          $sp_deleteTemplate(params).then(() => {
            this.getTableData();
          });
        })
        .catch(() => {});
    },
    // 打开新增模板弹窗
    addTemplateDialog() {
      this.templateForm.draft_id = '';
      this.templateForm.user_version = '';
      this.templateForm.user_desc = '';
      this.templateDialog = true;
      $sg_draftList().then((res) => {
        this.templateTableData = res;
      });
    },
    // 选择模板
    selectTemplate(row) {
      this.templateForm.draft_id = row.draft_id;
      this.templateForm.user_version = row.user_version;
      this.templateForm.user_desc = row.user_desc;
    },
    // 更改选中行背景色
    rowStyle({ row }) {
      if (this.templateForm.draft_id === row.draft_id) {
        return { 'background-color': '#F7EDED', cursor: 'pointer' };
      }
      return { cursor: 'pointer' };
    },
    // 草稿添加到代码模板库
    addToTemplate() {
      if (!this.templateForm.draft_id) {
        this.$message.error('请选择模板');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const params = {
        ...this.templateForm,
      };
      $sp_addToTemplate(params).then(() => {
        this.templateDialog = false;
        loading.close();
        this.getTableData();
      }).catch(() => {
        loading.close();
      });
    },
    // 模板列表翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss">
.template-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox{
    margin-top: 40px;
    .el-table{
      min-height: 580px;
    }
  }
  // 选择模板弹窗
  .selectTemplate {
    // 取消表格鼠标进入高亮显示
    .el-table__row:hover > td {
      background-color: transparent;
    }
  }
}
</style>

