import api from './api';


// 获取代码模板列表
export const $sg_templateList = (params) => api.get('/w/miniApp/templateList', params);

// 获取草稿列表
export const $sg_draftList = (params) => api.get('/w/miniApp/draftList', params);

// 草稿添加到代码模板库
export const $sp_addToTemplate = (params) => api.post('/w/miniApp/addToTemplate', params);

// 删除指定代码模板
export const $sp_deleteTemplate = (params) => api.post('/w/miniApp/deleteTemplate', params);

// 小程序列表
export const $sg_getMiniAppList = (params) => api.get('/w/miniApp/getMiniAppList', params);

// 创建小程序
export const $sp_createWxApp = (params) => api.post('/w/miniApp/createWxApp', params);

// 代码发布
export const $sp_releaseCode = (params) => api.post('/w/miniApp/releaseCode', params);

// 提交审核
export const $sp_commitCode = (params) => api.post('/w/miniApp/commitCode', params);

